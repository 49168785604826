var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { PageLayout } from "../../common/Components/Layout/page-layout";
import { Content, Name, Profession } from "./home.styled";
import { useTranslation } from "react-i18next";
export var Home = function () {
    var t = useTranslation().t;
    return (_jsx(PageLayout, __assign({ title: '' }, { children: _jsxs(Content, { children: [_jsx(Name, { children: t('myName') }), _jsx(Profession, { children: "Front-end Developer" })] }) })));
};
