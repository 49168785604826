var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from "styled-components";
export var Container = styled('ul')(function (_a) {
    var theme = _a.theme;
    return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    min-width: 100px;\n    height: 100vh;\n    background: ", ";\n    overflow: hidden;\n    position: sticky;\n    top: 0;\n    @media only screen and (max-width: ", ") {\n      display: none;\n    }\n  "], ["\n    min-width: 100px;\n    height: 100vh;\n    background: ", ";\n    overflow: hidden;\n    position: sticky;\n    top: 0;\n    @media only screen and (max-width: ", ") {\n      display: none;\n    }\n  "])), theme.colors.background, theme.breakpoints.tablet);
});
export var NavItem = styled('li')(function (_a) {
    var theme = _a.theme;
    return css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    width: 100%;\n    height: 100px;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    gap: 5px;\n    border-bottom: 2px solid ", ";\n    color: ", ";\n  "], ["\n    width: 100%;\n    height: 100px;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    gap: 5px;\n    border-bottom: 2px solid ", ";\n    color: ", ";\n  "])), theme.colors.divideColor, theme.colors.navbarText);
});
var templateObject_1, templateObject_2;
