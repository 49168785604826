var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef } from "react";
import emailjs from '@emailjs/browser';
import { Input, Group, TextArea, InputContainer, Send, InputsHeader, InputGroupContainer } from "./input-container.styled";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
export var InputGroup = function () {
    var t = useTranslation().t;
    var form = useRef(null);
    var sendEmail = function (e) {
        e.preventDefault();
        // @ts-ignore
        emailjs.sendForm('service_g3tdzgd', 'template_c8vlozk', form.current, 'D4SSPymbHZZgTaQzq')
            .then(function (result) {
            e.target.reset();
            toast.success(t('successfullySent'));
        }, function () {
            toast.error(t('errorSent'));
        });
    };
    return (_jsxs(InputGroupContainer, __assign({ ref: form, onSubmit: sendEmail }, { children: [_jsx(InputsHeader, { children: t('help') }), _jsxs(InputContainer, { children: [_jsxs(Group, { children: [_jsx(Input, { type: "text", placeholder: t('fullName'), name: "user_name", required: true }), _jsx(Input, { type: "email", placeholder: t('email'), name: "user_email", required: true }), _jsx(Input, { type: "text", placeholder: t('subject'), name: "subject", required: true })] }), _jsx(TextArea, { placeholder: t('message'), name: "message", required: true })] }), _jsx(Send, __assign({ type: "submit" }, { children: t('sendMessage') }))] })));
};
