var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
var Mail = function (props) { return (_jsxs("svg", __assign({ width: "100%", height: "100%", viewBox: "0 0 35 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props, { children: [_jsx("path", { d: "M32.0833 23.3333H2.91667C1.30813 23.3333 0 22.0252 0 20.4167V2.91667C0 1.30813 1.30813 0 2.91667 0H32.0833C33.6919 0 35 1.30813 35 2.91667V20.4167C35 22.0252 33.6919 23.3333 32.0833 23.3333ZM2.91667 1.45833C2.1124 1.45833 1.45833 2.1124 1.45833 2.91667V20.4167C1.45833 21.2209 2.1124 21.875 2.91667 21.875H32.0833C32.8876 21.875 33.5417 21.2209 33.5417 20.4167V2.91667C33.5417 2.1124 32.8876 1.45833 32.0833 1.45833H2.91667Z", fill: "currentColor" }), _jsx("path", { d: "M17.5001 15.4518L4.70829 7.17499C4.36995 6.95551 4.27297 6.50488 4.49172 6.16655C4.71047 5.82822 5.16183 5.73197 5.50016 5.94999L17.5001 13.7149L29.5 5.94999C29.8383 5.73197 30.2896 5.82822 30.5084 6.16655C30.7271 6.50488 30.6302 6.95551 30.2918 7.17499L17.5001 15.4518Z", fill: "currentColor" }), _jsx("path", { d: "M4.3757 18.9583C4.14018 18.9583 3.9083 18.8446 3.7683 18.6338C3.54445 18.2984 3.63487 17.8463 3.97028 17.6225L10.5328 13.2475C10.8682 13.0236 11.321 13.1141 11.5441 13.4495C11.768 13.7849 11.6776 14.237 11.3422 14.4608L4.77966 18.8358C4.65497 18.9182 4.51424 18.9583 4.3757 18.9583Z", fill: "currentColor" }), _jsx("path", { d: "M30.6242 18.9583C30.4857 18.9583 30.345 18.9182 30.2203 18.8358L23.6578 14.4608C23.3224 14.237 23.2319 13.7849 23.4558 13.4495C23.6789 13.1141 24.131 13.0236 24.4672 13.2475L31.0297 17.6225C31.3651 17.8463 31.4555 18.2984 31.2316 18.6338C31.0916 18.8446 30.8598 18.9583 30.6242 18.9583Z", fill: "currentColor" })] }))); };
export { Mail };
