var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { PageLayout } from "../../common/Components/Layout/page-layout";
import { About, FirstBlock, InfoBlock, InfoKey, InfoValue, SecondBlock, Title } from "./about-me.styled";
import { WhatIDoBlock } from "./WhatIDo/what-i-do-block";
import * as Icons from '../../common/Components/Icons';
export var AboutMe = function () {
    var t = useTranslation().t;
    return (_jsxs(PageLayout, __assign({ title: t('aboutMe') }, { children: [_jsxs(FirstBlock, { children: [_jsx(About, { children: t('aboutMeText') }), _jsxs(InfoBlock, { children: [_jsxs("div", { children: [_jsxs(InfoKey, { children: [t('age'), ": "] }), _jsx(InfoValue, { children: "28" })] }), _jsxs("div", { children: [_jsxs(InfoKey, { children: [t('residence'), ": "] }), _jsx(InfoValue, { children: t('armenia') })] }), _jsxs("div", { children: [_jsx(InfoKey, { children: "e-mail: " }), _jsx(InfoValue, { children: "arenmatinyan95@gmail.com" })] }), _jsxs("div", { children: [_jsxs(InfoKey, { children: [t('phone'), ": "] }), _jsx(InfoValue, { children: "+37493793469" })] }), _jsxs("div", { children: [_jsx(InfoKey, { children: "telegram: " }), _jsx(InfoValue, { children: "arenmatinyan" })] })] })] }), _jsx(Title, { children: t('whatIDo') }), _jsxs(SecondBlock, { children: [_jsx(WhatIDoBlock, { title: 'Frontend', icon: _jsx(Icons.Code, { width: 40, height: 32 }), text: t('frontendDescription') }), _jsx(WhatIDoBlock, { title: 'Photography', icon: _jsx(Icons.Camera, { width: 40, height: 32 }), text: t('photographyDescription') })] })] })));
};
