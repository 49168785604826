var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from "styled-components";
export var BlockContainer = styled('div')(function (_a) {
    var theme = _a.theme;
    return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n    width: 45%;\n    @media only screen and (max-width: ", ") {\n      width: 100%;\n    }\n  "], ["\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n    width: 45%;\n    @media only screen and (max-width: ", ") {\n      width: 100%;\n    }\n  "])), theme.breakpoints.laptop);
});
export var BlockTitle = styled('h3')(function (_a) {
    var theme = _a.theme;
    return css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    color: ", ";\n    @media only screen and (max-width: ", ") {\n      font-size: 22px;\n    }\n  "], ["\n    color: ", ";\n    @media only screen and (max-width: ", ") {\n      font-size: 22px;\n    }\n  "])), theme.colors.lightText, theme.breakpoints.laptop);
});
var templateObject_1, templateObject_2;
