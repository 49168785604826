import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import en from './locales/en/common.json';
import ru from './locales/ru/common.json';
import hy from './locales/hy/common.json';
var resources = {
    en: {
        transaction: en,
    },
    ru: {
        transaction: ru,
    },
    hy: {
        transaction: hy,
    },
};
i18next
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
    debug: false,
    defaultNS: 'transaction',
    fallbackLng: 'en',
    interpolation: {
        escapeValue: false,
    },
    resources: resources,
});
export default i18next;
