var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from "styled-components";
export var InfoCard = styled('div')(function (_a) {
    var theme = _a.theme;
    return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: flex;\n    flex-direction: column;\n    width: 280px;\n    height: 110px;\n    justify-content: center;\n    align-items: center;\n    gap: 5px;\n    background: ", ";\n    border: 2px solid ", ";\n    @media only screen and (max-width: ", ") {\n      width: 100%;\n    }\n  "], ["\n    display: flex;\n    flex-direction: column;\n    width: 280px;\n    height: 110px;\n    justify-content: center;\n    align-items: center;\n    gap: 5px;\n    background: ", ";\n    border: 2px solid ", ";\n    @media only screen and (max-width: ", ") {\n      width: 100%;\n    }\n  "])), theme.colors.infoCard, theme.colors.cardBorder, theme.breakpoints.tablet);
});
export var InfoText = styled('span')(function (_a) {
    var theme = _a.theme;
    return css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    font-size: 16px;\n    color: ", ";\n  "], ["\n    font-size: 16px;\n    color: ", ";\n  "])), theme.colors.lightText);
});
export var CardsContainer = styled('div')(function () { return css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    display: flex;\n    flex-direction: column;\n    gap: 12px;\n  "], ["\n    display: flex;\n    flex-direction: column;\n    gap: 12px;\n  "]))); });
var templateObject_1, templateObject_2, templateObject_3;
