var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as Icons from "../Icons";
import { SocialLinks } from "./link-to-profiles.styled";
export var LinkToProfiles = function () {
    return (_jsxs(SocialLinks, { children: [_jsx("a", __assign({ href: "https://linkedin.com/in/aren-matinyan-9a6140195", target: '_blank', rel: "noreferrer" }, { children: _jsx(Icons.Linkedin, { width: 24, height: 24 }) })), _jsx("a", __assign({ href: "https://gitlab.com/aren.matinyan", target: '_blank', rel: "noreferrer" }, { children: _jsx(Icons.Gitlab, { width: 24, height: 24 }) }))] }));
};
