var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
var Linkedin = function (props) { return (_jsx("svg", __assign({ width: "100%", height: "100%", viewBox: "0 0 35 34", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props, { children: _jsx("path", { d: "M0.443833 11.0587H7.94475V34H0.443833V11.0587ZM4.2445 0C1.67725 0 0 1.71294 0 3.96124C0 6.16329 1.62817 7.92603 4.14633 7.92603H4.19417C6.8105 7.92603 8.43983 6.1632 8.43983 3.96124C8.39075 1.71294 6.81058 0 4.2445 0ZM26.3616 10.5198C22.38 10.5198 20.5963 12.7456 19.6012 14.3065V11.0587H12.098C12.1973 13.2109 12.098 34 12.098 34H19.6012V21.188C19.6012 20.5018 19.6502 19.8181 19.8477 19.3266C20.3909 17.9567 21.6254 16.5382 23.6962 16.5382C26.413 16.5382 27.498 18.6429 27.498 21.7255V33.9999H35V20.845C35 13.7985 31.2998 10.5198 26.3616 10.5198Z", fill: "#F5F5F5" }) }))); };
export { Linkedin };
