var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
var Home = function (props) { return (_jsx("svg", __assign({ width: "100%", height: "100%", viewBox: "0 0 35 37", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props, { children: _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M34.7619 18.2583C34.4444 18.5796 33.9286 18.5796 33.6111 18.2583L26.162 10.7418C26.1086 10.6989 26.0628 10.6527 26.0203 10.598L22.8287 7.37799C22.7754 7.3351 22.7295 7.28885 22.687 7.23418L17.4996 1.9992L1.38894 18.2583C1.07145 18.5796 0.555618 18.5796 0.238122 18.2583C-0.0793741 17.9379 -0.0793741 17.4174 0.238122 17.097L16.8254 0.357645C16.8513 0.318119 16.8696 0.272708 16.9038 0.236546C17.0679 0.0717178 17.2846 -0.00480953 17.4996 0.000236239C17.7146 -0.00480953 17.9312 0.0708769 18.0954 0.236546C18.1312 0.271867 18.1471 0.317278 18.1754 0.357645L22.4995 4.72307V2.5206C22.4995 2.05639 22.872 1.67964 23.3328 1.67964H26.6661C26.897 1.67964 27.1053 1.77382 27.2553 1.92604C27.407 2.07825 27.4995 2.28849 27.4995 2.5206V9.76884L34.7619 17.097C35.0794 17.4174 35.0794 17.9379 34.7619 18.2583ZM25.8328 3.36156H24.1662V6.40499L25.8328 8.08692V3.36156ZM5.83306 16.8169C6.29305 16.8169 6.66638 17.1937 6.66638 17.6579V33.6362C6.66638 34.5654 7.41304 35.3181 8.33303 35.3181H13.333V24.3856C13.333 23.9205 13.7055 23.5446 14.1663 23.5446H20.8329C21.2937 23.5446 21.6662 23.9205 21.6662 24.3856V35.3181H26.6661C27.587 35.3181 28.3328 34.5654 28.3328 33.6362V17.6579C28.3328 17.1937 28.7053 16.8169 29.1661 16.8169C29.6269 16.8169 29.9994 17.1937 29.9994 17.6579V33.6362C29.9994 35.4938 28.507 37 26.6661 37H8.33303C6.49221 37 4.99973 35.4938 4.99973 33.6362V17.6579C4.99973 17.1928 5.37306 16.8169 5.83306 16.8169ZM19.9996 35.3172V25.2257H14.9996V35.3172H19.9996Z", fill: "currentColor" }) }))); };
export { Home };
