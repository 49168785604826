var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from "styled-components";
export var TimelineContainer = styled('div')(function () { return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: flex;\n    gap: 20px;\n  "], ["\n    display: flex;\n    gap: 20px;\n  "]))); });
export var Experience = styled('div')(function () { return css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    margin-top: 18px;\n    width: 115px;\n    min-width: 115px;\n  "], ["\n    margin-top: 18px;\n    width: 115px;\n    min-width: 115px;\n  "]))); });
export var ExperienceDuration = styled('p')(function (_a) {
    var theme = _a.theme;
    return css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    color: ", ";\n    display: flex;\n    justify-content: end;\n    text-wrap: avoid;\n    text-align: right;\n  "], ["\n    color: ", ";\n    display: flex;\n    justify-content: end;\n    text-wrap: avoid;\n    text-align: right;\n  "])), theme.colors.text);
});
export var VerticalTimeline = styled('div')(function () { return css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    display: flex;\n    align-items: start;\n    justify-content: center;\n    position: relative;\n  "], ["\n    display: flex;\n    align-items: start;\n    justify-content: center;\n    position: relative;\n  "]))); });
export var Divider = styled('div')(function (_a) {
    var theme = _a.theme;
    return css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    width: 2px;\n    height: 100%;\n    background: ", ";\n  "], ["\n    width: 2px;\n    height: 100%;\n    background: ", ";\n  "])), theme.colors.divideColor);
});
export var IconContainer = styled('div')(function () { return css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    position: absolute;\n    top: 20px;\n  "], ["\n    position: absolute;\n    top: 20px;\n  "]))); });
export var Title = styled('h3')(function (_a) {
    var theme = _a.theme;
    return css(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    margin-top: 15px;\n    color: ", ";\n    @media only screen and (max-width: ", ") {\n      font-size: 18px;\n    }\n  "], ["\n    margin-top: 15px;\n    color: ", ";\n    @media only screen and (max-width: ", ") {\n      font-size: 18px;\n    }\n  "])), theme.colors.lightText, theme.breakpoints.laptop);
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
