var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from "styled-components";
import { StyledButton } from "../../../theme/styled-elements.styled";
export var InputGroupContainer = styled('form')(function () { return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: flex;\n    flex-direction: column;\n    width: 100%;\n  "], ["\n    display: flex;\n    flex-direction: column;\n    width: 100%;\n  "]))); });
export var InputsHeader = styled('h2')(function (_a) {
    var theme = _a.theme;
    return css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    color: ", ";\n  "], ["\n    color: ", ";\n  "])), theme.colors.lightText);
});
export var InputContainer = styled('div')(function (_a) {
    var theme = _a.theme;
    return css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    display: flex;\n    margin-top: 20px;\n    gap: 20px;\n    @media only screen and (max-width: ", ") {\n      flex-direction: column;\n    }\n  "], ["\n    display: flex;\n    margin-top: 20px;\n    gap: 20px;\n    @media only screen and (max-width: ", ") {\n      flex-direction: column;\n    }\n  "])), theme.breakpoints.laptopL);
});
export var Group = styled('div')(function (_a) {
    var theme = _a.theme;
    return css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    display: flex;\n    width: 80%;\n    flex-direction: column;\n    gap: 20px;\n    @media only screen and (max-width: ", ") {\n      width: 100%;\n    }\n  "], ["\n    display: flex;\n    width: 80%;\n    flex-direction: column;\n    gap: 20px;\n    @media only screen and (max-width: ", ") {\n      width: 100%;\n    }\n  "])), theme.breakpoints.laptopL);
});
export var TextArea = styled('textarea')(function (_a) {
    var theme = _a.theme;
    return css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    height: 160px;\n    width: 100%;\n    border: 2px solid ", ";\n    border-radius: ", ";\n    background: transparent;\n    padding: 15px;\n    color: ", ";\n    font-size: 16px;\n    resize: none;\n  "], ["\n    height: 160px;\n    width: 100%;\n    border: 2px solid ", ";\n    border-radius: ", ";\n    background: transparent;\n    padding: 15px;\n    color: ", ";\n    font-size: 16px;\n    resize: none;\n  "])), theme.colors.input, theme.borderRadius.input, theme.colors.lightText);
});
export var Send = styled(StyledButton)(function (_a) {
    var theme = _a.theme;
    return css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    color: ", ";\n    border: 2px solid ", ";\n    margin-top: 15px;\n    width: fit-content;\n    padding: 0 10px;\n    min-width: 190px;\n\n    &:hover {\n      color: ", ";\n      border: 2px solid ", ";\n    }\n  "], ["\n    color: ", ";\n    border: 2px solid ", ";\n    margin-top: 15px;\n    width: fit-content;\n    padding: 0 10px;\n    min-width: 190px;\n\n    &:hover {\n      color: ", ";\n      border: 2px solid ", ";\n    }\n  "])), theme.colors.lightText, theme.colors.input, theme.colors.blue, theme.colors.blue);
});
export var Input = styled('input')(function (_a) {
    var theme = _a.theme;
    return css(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    height: 40px;\n    width: 100%;\n    border: 2px solid ", ";\n    border-radius: ", ";\n    background: transparent;\n    padding-left: 15px;\n    color: ", ";\n    font-size: 18px;\n  "], ["\n    height: 40px;\n    width: 100%;\n    border: 2px solid ", ";\n    border-radius: ", ";\n    background: transparent;\n    padding-left: 15px;\n    color: ", ";\n    font-size: 18px;\n  "])), theme.colors.input, theme.borderRadius.input, theme.colors.lightText);
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
