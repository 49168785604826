var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from "styled-components";
export var Content = styled('div')(function () { return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    height: 80%;\n  "], ["\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    height: 80%;\n  "]))); });
export var Name = styled('h1')(function (_a) {
    var theme = _a.theme;
    return css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    color: ", ";\n    letter-spacing: 2px;\n    text-align: center;\n  "], ["\n    color: ", ";\n    letter-spacing: 2px;\n    text-align: center;\n  "])), theme.colors.lightText);
});
export var Profession = styled('h4')(function (_a) {
    var theme = _a.theme;
    return css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    color: ", ";\n    letter-spacing: 2px;\n  "], ["\n    color: ", ";\n    letter-spacing: 2px;\n  "])), theme.colors.text);
});
var templateObject_1, templateObject_2, templateObject_3;
