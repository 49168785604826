var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
var Location = function (props) { return (_jsxs("svg", __assign({ width: "100%", height: "100%", viewBox: "0 0 24 34", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props, { children: [_jsx("path", { d: "M11.8571 33.2L11.2219 32.4279C10.7985 31.8664 0 19.3023 0 11.792C0 5.26428 5.36395 0 11.8571 0C18.3503 0 23.7143 5.33447 23.7143 11.792C23.7143 19.3023 12.9158 31.9366 12.4923 32.4279L11.8571 33.2ZM11.8571 1.61439C6.21089 1.61439 1.69388 6.17675 1.69388 11.7218C1.69388 17.6178 9.52806 27.655 11.8571 30.5328C14.1862 27.655 22.0204 17.688 22.0204 11.7218C22.0204 6.17675 17.5034 1.61439 11.8571 1.61439Z", fill: "#04B4E0" }), _jsx("path", { d: "M11.8573 16.2841C9.3871 16.2841 7.34033 14.2486 7.34033 11.7919C7.34033 9.33528 9.3871 7.29974 11.8573 7.29974C14.3276 7.29974 16.3743 9.33528 16.3743 11.7919C16.3743 14.2486 14.3276 16.2841 11.8573 16.2841ZM11.8573 8.98433C10.3046 8.98433 9.0342 10.2478 9.0342 11.7919C9.0342 13.3361 10.3046 14.5995 11.8573 14.5995C13.4101 14.5995 14.6805 13.3361 14.6805 11.7919C14.6805 10.2478 13.4101 8.98433 11.8573 8.98433Z", fill: "#04B4E0" })] }))); };
export { Location };
