var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Container, Name, DownloadCV } from "./short-info.styled";
import { useCallback } from "react";
import CV from '../../assets/CV-Aren-Мatinyan.pdf';
import { ProfileImage } from "../ProfileImage/profile-image";
import { Description } from "../../../theme/styled-elements.styled";
import { LinkToProfiles } from "../LinkToProfiles/link-to-profiles";
import { useTranslation } from "react-i18next";
export var ShortInfo = function () {
    var t = useTranslation().t;
    var downloadCv = useCallback(function () {
        window.open(CV, '_blank');
    }, []);
    return (_jsxs(Container, { children: [_jsx(ProfileImage, {}), _jsx(Name, { children: t('myName') }), _jsx(Description, { children: "Front-end Developer" }), _jsx(LinkToProfiles, {}), _jsx(DownloadCV, __assign({ onClick: downloadCv }, { children: t('downloadCv') }))] }));
};
