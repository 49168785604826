var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { PageLayout } from "../../common/Components/Layout/page-layout";
import { Title } from "../Resume/Timeline/timeline.styled";
import PhotoAlbum from "react-photo-album";
import { useCallback, useEffect, useState } from "react";
import { AlbumPhoto, Description, IconButton, SocialNetworks } from "../../theme/styled-elements.styled";
import { photoGallery } from "../../common/assets/Gallery/Gallery";
import * as Icons from '../../common/Components/Icons';
import StyledModal from "../../common/Components/StyledModal/StyledModal";
var breakpoints = [3840, 2400, 1080, 640, 384, 256, 128, 96, 64, 48];
export var Portfolio = function () {
    var t = useTranslation().t;
    var _a = useState([]), gallery = _a[0], setGallery = _a[1];
    var _b = useState(null), selectedPhotoIdx = _b[0], setSelectedPhotoIdx = _b[1];
    var galleryArray = useCallback(function () {
        Promise.all(photoGallery
            .map(function (photo) {
            var url = photo.src;
            var img = new Image();
            img.src = url;
            var onLoad = function () {
                return new Promise(function (resolve) {
                    var checkSize = function () {
                        if (img.width > 0 && img.height > 0) {
                            var height = img.height;
                            var width = img.width;
                            var photoObj = {
                                width: width,
                                height: height,
                                img: photo.src,
                                name: photo.name,
                                id: photo.id,
                                location: photo.location,
                            };
                            resolve(photoObj);
                        }
                        else {
                            setTimeout(checkSize, 100);
                        }
                    };
                    checkSize();
                });
            };
            return onLoad();
        }))
            .then(function (responsesArray) {
            setGallery(responsesArray);
        });
    }, []);
    useEffect(function () {
        galleryArray();
    }, [galleryArray]);
    var photos = gallery === null || gallery === void 0 ? void 0 : gallery.map(function (photo) {
        var url = photo.img;
        var width = breakpoints[0];
        var height = (photo.height / photo.width) * width;
        return {
            src: url,
            width: width,
            height: height,
            name: photo.name,
            id: photo.id,
            location: photo.location,
        };
    });
    var handleItemClick = function (_a) {
        var photo = _a.photo;
        var selectedPhotoIdx = photos.findIndex(function (el) { return el.id === photo.id; });
        setSelectedPhotoIdx(selectedPhotoIdx);
    };
    var handleClose = function () {
        setSelectedPhotoIdx(null);
    };
    return (_jsxs(PageLayout, __assign({ title: t('portfolio') }, { children: [_jsx(Title, { children: t('besidesProgramming') }), _jsx(Description, { children: t('besidesProgrammingDescription') }), _jsxs(SocialNetworks, { children: [_jsx(IconButton, __assign({ href: 'https://500px.com/p/arenmatinyan', target: '_blank' }, { children: _jsx(Icons.Px500, { width: 45 }) })), _jsx(IconButton, __assign({ href: 'https://www.youtube.com/@arenmatinyan95', target: '_blank' }, { children: _jsx(Icons.YouTube, { width: 35 }) })), _jsx(IconButton, __assign({ href: 'https://instagram.com/armat.33?igshid=NGVhN2U2NjQ0Yg==', target: '_blank' }, { children: _jsx(Icons.Instagram, { width: 35 }) }))] }), _jsx(PhotoAlbum, { layout: "rows", photos: photos, onClick: handleItemClick, renderPhoto: function (_a) {
                    var wrapperStyle = _a.wrapperStyle, renderDefaultPhoto = _a.renderDefaultPhoto;
                    return (_jsx(AlbumPhoto, __assign({ style: __assign({}, wrapperStyle) }, { children: renderDefaultPhoto({ wrapped: true }) })));
                } }), _jsx(StyledModal, { selectedPhotoIdx: selectedPhotoIdx, close: handleClose })] })));
};
