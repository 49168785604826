var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useRef } from "react";
import { BackgroundSwiper } from "./SwiperBackground.styles";
import { Swiper } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
var SwiperBackground = function (_a) {
    var children = _a.children, initialSlide = _a.initialSlide;
    var swiperRef = useRef();
    var handleClick = function (e) {
        e.stopPropagation();
    };
    return (_jsx(BackgroundSwiper, __assign({ style: { position: "relative", width: "auto" }, onClick: handleClick }, { children: _jsx(Swiper, __assign({ onBeforeInit: function (swiper) {
                swiperRef.current = swiper;
            }, initialSlide: initialSlide, slidesPerView: 1, pagination: {
                dynamicBullets: true,
                clickable: true,
            }, spaceBetween: 20, loopFillGroupWithBlank: true, grabCursor: true }, { children: children })) })));
};
export default SwiperBackground;
