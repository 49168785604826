var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css, keyframes } from "styled-components";
import { Link } from "react-router-dom";
var slideInFromRight = keyframes(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  0% {\n    transform: translateX(100%);\n  }\n  100% {\n    transform: translateX(0);\n  }\n"], ["\n  0% {\n    transform: translateX(100%);\n  }\n  100% {\n    transform: translateX(0);\n  }\n"])));
export var MenuContainer = styled('div')(function (_a) {
    var theme = _a.theme;
    return css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    animation: ", " 0.5s ease-in-out;\n    background: ", ";\n    height: 100vh;\n    width: 300px;\n    position: fixed;\n    top: 0;\n    right: 0;\n    padding: 30px 20px;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    z-index: 5;\n  "], ["\n    animation: ", " 0.5s ease-in-out;\n    background: ", ";\n    height: 100vh;\n    width: 300px;\n    position: fixed;\n    top: 0;\n    right: 0;\n    padding: 30px 20px;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    z-index: 5;\n  "])), slideInFromRight, theme.colors.infoBackground);
});
export var RoutesContainer = styled('div')(function () { return css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    margin: 7px 0;\n  "], ["\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    margin: 7px 0;\n  "]))); });
export var Route = styled(Link)(function (_a) {
    var theme = _a.theme, isCurrent = _a.isCurrent;
    return css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    color: ", ";\n    line-height: 40px;\n  "], ["\n    color: ", ";\n    line-height: 40px;\n  "])), isCurrent ? theme.colors.blue : theme.colors.text);
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
