var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Container, NavItem } from "./navbar.styled";
import * as Icons from "../Icons";
import { Link, useLocation } from "react-router-dom";
import { Routes } from "../../const/routes";
import { useTranslation } from "react-i18next";
export var Navbar = function () {
    var t = useTranslation().t;
    var location = useLocation();
    return (_jsxs(Container, { children: [_jsx(Link, __assign({ to: Routes.HOME }, { children: _jsxs(NavItem, { children: [_jsx(Icons.Home, { color: location.pathname === Routes.HOME ? '#04B4E0' : '#B5B6B7', width: 35, height: 35 }), _jsx("span", { children: t('home') })] }) })), _jsx(Link, __assign({ to: Routes.RESUME }, { children: _jsxs(NavItem, { children: [_jsx(Icons.Graduate, { color: location.pathname === Routes.RESUME ? '#04B4E0' : '#B5B6B7', width: 35, height: 35 }), _jsx("span", { children: t('resume') })] }) })), _jsx(Link, __assign({ to: Routes.PORTFOLIO }, { children: _jsxs(NavItem, { children: [_jsx(Icons.Portfolio, { color: location.pathname === Routes.PORTFOLIO ? '#04B4E0' : '#B5B6B7', width: 35, height: 35 }), _jsx("span", { children: t('portfolio') })] }) })), _jsx(Link, __assign({ to: Routes.CONTACT }, { children: _jsxs(NavItem, { children: [_jsx(Icons.Mail, { color: location.pathname === Routes.CONTACT ? '#04B4E0' : '#B5B6B7', width: 35, height: 35 }), _jsx("span", { children: t('contact') })] }) }))] }));
};
