var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { PageLayout } from "../../common/Components/Layout/page-layout";
import { Timeline } from "./Timeline/timeline";
import { Block, BlockTitle, Knowledge, KnowledgeBlock, Skill, SkillsBlock, TimelineContainer } from "./resume.styled";
var skills = ['JavaScript', 'TypeScript', 'React', 'Redux', 'GraphQL', 'Apollo Client', 'Next.js', 'HTML', 'CSS', 'Styled Components', 'RxJS', 'Git'];
var knowledges = ['Social Media', 'Time Management', 'Communication', 'Problem-Solving', 'Social Networking', 'Flexibility'];
export var Resume = function () {
    var t = useTranslation().t;
    return (_jsxs(PageLayout, __assign({ title: t('resume') }, { children: [_jsxs(Block, { children: [_jsxs(TimelineContainer, { children: [_jsx(BlockTitle, { children: t('experience') }), _jsx(Timeline, { duration: '2021-present ', company: 'Devinnotech', position: 'Frontend developer', description: t('devinnotechJobDescription') }), _jsx(Timeline, { duration: '2020-2021', company: 'NWSLab', position: 'Frontend developer', description: t('nwsJobDescription') }), _jsx(Timeline, { duration: '2019-2020', company: 'Ucom', position: 'Technical support specialist', description: t('ucomJobDescription') })] }), _jsxs(SkillsBlock, { children: [_jsx(BlockTitle, { children: t('skills') }), skills.map(function (skill) { return _jsx(Skill, { children: skill }, skill); })] })] }), _jsxs(Block, { children: [_jsxs(TimelineContainer, { children: [_jsx(BlockTitle, { children: t('education') }), _jsx(Timeline, { duration: '2020', company: 'Armenian code academy', position: 'Engineering Apprentice', description: "JavaScript, React.js, Redux, TypeScript" }), _jsx(Timeline, { duration: '2012-2016', company: 'Yerevan State University', position: 'Faculty of Geography and Geology', description: "Bachelor's degree in Geology" })] }), _jsxs(KnowledgeBlock, { children: [_jsx(BlockTitle, { children: t('knowledges') }), knowledges.map(function (k) { return _jsx(Knowledge, { children: k }, k); })] })] })] })));
};
