var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from "styled-components";
export var ImageCropper = styled('div')(function (_a) {
    var theme = _a.theme;
    return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    width: 200px;\n    height: 200px;\n    position: relative;\n    overflow: hidden;\n    margin-top: 70px;\n    margin-bottom: 20px;\n    border-radius: 50%;\n    border: 2px solid ", ";\n    @media only screen and (max-width: ", ") {\n      width: 130px;\n      height: 130px;\n      margin-top: 20px;\n    }\n  "], ["\n    width: 200px;\n    height: 200px;\n    position: relative;\n    overflow: hidden;\n    margin-top: 70px;\n    margin-bottom: 20px;\n    border-radius: 50%;\n    border: 2px solid ", ";\n    @media only screen and (max-width: ", ") {\n      width: 130px;\n      height: 130px;\n      margin-top: 20px;\n    }\n  "])), theme.colors.navbarText, theme.breakpoints.tablet);
});
export var Image = styled('img')(function () { return css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    display: inline;\n    margin: 0 auto;\n    height: 100%;\n    width: auto;\n  "], ["\n    display: inline;\n    margin: 0 auto;\n    height: 100%;\n    width: auto;\n  "]))); });
var templateObject_1, templateObject_2;
