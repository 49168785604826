var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
var Hmburger = function (props) { return (_jsx("svg", __assign({ width: "100%", height: "100%", viewBox: "0 0 35 32", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props, { children: _jsx("path", { d: "M2.5 6.4H32.5C33.88 6.4 35 4.9664 35 3.2C35 1.4336 33.88 0 32.5 0H2.5C1.12 0 0 1.4336 0 3.2C0 4.9664 1.12 6.4 2.5 6.4ZM32.5 12.8H2.5C1.12 12.8 0 14.2336 0 16C0 17.7664 1.12 19.2 2.5 19.2H32.5C33.88 19.2 35 17.7664 35 16C35 14.2336 33.88 12.8 32.5 12.8ZM32.5 25.6H2.5C1.12 25.6 0 27.0336 0 28.8C0 30.5664 1.12 32 2.5 32H32.5C33.88 32 35 30.5664 35 28.8C35 27.0336 33.88 25.6 32.5 25.6Z", fill: "#04B4E0" }) }))); };
export { Hmburger };
