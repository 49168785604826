var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { MenuContainer, Route, RoutesContainer } from "./hamburger-menu.styled";
import { DownloadCV, Name } from "../ShortInfo/short-info.styled";
import { useCallback } from "react";
import CV from "../../assets/CV-Aren-Мatinyan.pdf";
import { Routes } from "../../const/routes";
import { useLocation } from "react-router-dom";
import { ProfileImage } from "../ProfileImage/profile-image";
import { Description } from "../../../theme/styled-elements.styled";
import { LinkToProfiles } from "../LinkToProfiles/link-to-profiles";
import { useTranslation } from "react-i18next";
import { LanguageSwitcher } from "../LanguageSwitcher/language-switcher";
export var HamburgerMenu = function () {
    var location = useLocation();
    var t = useTranslation().t;
    var downloadCv = useCallback(function () {
        window.open(CV, '_blank');
    }, []);
    return (_jsxs(MenuContainer, { children: [_jsx(ProfileImage, {}), _jsx(Name, { children: t('myName') }), _jsx(Description, { children: "Front-end Developer" }), _jsxs(RoutesContainer, { children: [_jsx(Route, __assign({ to: Routes.HOME, isCurrent: location.pathname === Routes.HOME }, { children: t('home') })), _jsx(Route, __assign({ to: Routes.RESUME, isCurrent: location.pathname === Routes.RESUME }, { children: t('resume') })), _jsx(Route, __assign({ to: Routes.PORTFOLIO, isCurrent: location.pathname === Routes.PORTFOLIO }, { children: t('portfolio') })), _jsx(Route, __assign({ to: Routes.CONTACT, isCurrent: location.pathname === Routes.CONTACT }, { children: t('contact') }))] }), _jsx(LinkToProfiles, {}), _jsx(DownloadCV, __assign({ onClick: downloadCv }, { children: t('downloadCv') })), _jsx(LanguageSwitcher, {})] }));
};
