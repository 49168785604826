export var colors = {
    background: '#1E1E1E',
    text: '#AAAAAA',
    lightText: '#F5F5F5',
    navbarText: '#B5B6B7',
    divideColor: '#444444',
    infoBackground: '#444444',
    blue: '#04B4E0',
    input: '#A5A6A7',
    infoCard: '#333333',
    cardBorder: '#292929',
    skills: '#EEEEEE'
};
