var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import SwiperBackground from "../SwiperBackground/SwiperBackground";
import { photoGallery } from "../../assets/Gallery/Gallery";
import { SwiperSlide } from "swiper/react";
import { ImageWrapper } from "./StyledModal.styles";
var StyledModal = function (_a) {
    var selectedPhotoIdx = _a.selectedPhotoIdx, close = _a.close;
    return (_jsx(ImageWrapper, __assign({ onClick: close }, { children: typeof selectedPhotoIdx === "number" && (_jsx("div", __assign({ className: "popup" }, { children: _jsx("div", __assign({ className: "popup-content" }, { children: _jsx(SwiperBackground, __assign({ initialSlide: selectedPhotoIdx }, { children: photoGallery.map(function (el) {
                        return (_jsx(SwiperSlide, __assign({ style: { display: "flex", justifyContent: "center", alignItems: "center" } }, { children: _jsx("img", { className: "imagePopup", src: el.src, alt: '' }) }), el.src));
                    }) })) })) }))) })));
};
export default StyledModal;
