var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from "styled-components";
import { StyledButton } from "../../../theme/styled-elements.styled";
export var Container = styled('div')(function (_a) {
    var theme = _a.theme;
    return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    min-width: 420px;\n    height: 100vh;\n    background: ", ";\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    position: sticky;\n    top: 0;\n    @media only screen and (max-width: ", ") {\n      display: none;\n    }\n    @media only screen and (max-width: ", ") {\n      min-width: 340px;\n    }\n  "], ["\n    min-width: 420px;\n    height: 100vh;\n    background: ", ";\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    position: sticky;\n    top: 0;\n    @media only screen and (max-width: ", ") {\n      display: none;\n    }\n    @media only screen and (max-width: ", ") {\n      min-width: 340px;\n    }\n  "])), theme.colors.infoBackground, theme.breakpoints.tablet, theme.breakpoints.laptop);
});
export var Name = styled('h2')(function (_a) {
    var theme = _a.theme;
    return css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    color: ", ";\n    @media only screen and (max-width: ", ") {\n      font-size: 26px;\n    }\n  "], ["\n    color: ", ";\n    @media only screen and (max-width: ", ") {\n      font-size: 26px;\n    }\n  "])), theme.colors.lightText, theme.breakpoints.tablet);
});
export var DownloadCV = styled(StyledButton)(function (_a) {
    var theme = _a.theme;
    return css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    border: 2px solid ", ";\n    color: ", ";\n    margin-bottom: 10px;\n    &:hover {\n      background: ", ";\n      color: ", ";\n    }\n  "], ["\n    border: 2px solid ", ";\n    color: ", ";\n    margin-bottom: 10px;\n    &:hover {\n      background: ", ";\n      color: ", ";\n    }\n  "])), theme.colors.lightText, theme.colors.lightText, theme.colors.lightText, theme.colors.blue);
});
var templateObject_1, templateObject_2, templateObject_3;
