var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
var Phone = function (props) { return (_jsxs("svg", __assign({ width: "100%", height: "100%", viewBox: "0 0 30 30", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props, { children: [_jsx("g", __assign({ clipPath: "url(#clip0_17_834)" }, { children: _jsx("path", { d: "M18.1301 21.1512C18.1301 21.1512 20.5595 19.7604 21.1955 19.4262C21.8297 19.0908 22.4867 19.0062 22.8857 19.2498C23.4899 19.6194 28.5635 22.9944 28.9907 23.2932C29.4179 23.5926 29.6237 24.4476 29.0363 25.2852C28.4513 26.1228 25.7525 29.4348 24.6089 29.3994C23.4635 29.3622 18.7013 29.2578 9.72111 20.2752C0.74271 11.2956 0.63651 6.53219 0.59991 5.38679C0.56331 4.24199 3.87531 1.54259 4.71291 0.956985C5.55171 0.371985 6.40791 0.592185 6.70551 1.00379C7.04391 1.47239 10.3799 6.52979 10.7471 7.10939C10.9985 7.50539 10.9061 8.16599 10.5707 8.80079C10.2371 9.43679 8.84631 11.8662 8.84631 11.8662C8.84631 11.8662 9.82671 13.5384 13.1423 16.8534C16.4585 20.169 18.1301 21.1512 18.1301 21.1512Z", stroke: "#04B4E0", strokeWidth: "2", strokeMiterlimit: "10" }) })), _jsx("defs", { children: _jsx("clipPath", __assign({ id: "clip0_17_834" }, { children: _jsx("rect", { width: "30", height: "30", fill: "white" }) })) })] }))); };
export { Phone };
