var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
var Code = function (props) { return (_jsx("svg", __assign({ width: "100%", height: "100%", viewBox: "0 0 35 28", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props, { children: _jsx("path", { d: "M22.75 1.74672C22.75 1.18945 22.318 0 21.0055 0C20.2409 0 19.536 0.496125 19.3162 1.26766L12.3162 25.7677C12.2702 25.9282 12.2483 26.0899 12.2483 26.249C12.2008 26.8133 12.6875 28 14 28C14.7612 28 15.4618 27.4992 15.6816 26.7302L22.6816 2.23016C22.7281 2.06773 22.75 1.90586 22.75 1.74672ZM9.625 7.82578C9.625 6.82609 8.80742 6.07578 7.875 6.07578C7.42722 6.07578 6.97922 6.24668 6.63797 6.58853L0.512969 12.7135C0.170898 13.1031 0 13.5516 0 13.9508C0 14.35 0.170898 14.8969 0.512695 15.2359L6.6377 21.3609C6.97813 21.7055 7.42656 21.8258 7.875 21.8258C8.80742 21.8258 9.625 21.0749 9.625 20.0758C9.625 19.628 9.4541 19.18 9.1123 18.8382L4.22461 13.9508L9.11258 9.06281C9.45547 8.76641 9.625 8.32344 9.625 7.82578ZM35 13.9508C35 13.503 34.8291 13.055 34.4873 12.7132L28.3623 6.5882C28.0219 6.29453 27.5734 6.07578 27.125 6.07578C26.1926 6.07578 25.375 6.82664 25.375 7.82578C25.375 8.27356 25.5459 8.72156 25.8877 9.06336L30.7757 13.9513L25.8877 18.8393C25.5445 19.2281 25.375 19.6766 25.375 20.0758C25.375 21.0755 26.1926 21.8258 27.125 21.8258C27.5728 21.8258 28.0208 21.6549 28.362 21.313L34.487 15.188C34.8305 14.8969 35 14.4484 35 13.9508Z", fill: "#04B4E0" }) }))); };
export { Code };
