var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
var YouTube = function (props) { return (_jsxs("svg", __assign({ width: "100%", height: "100%", viewBox: "0 0 35 25", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props, { children: [_jsx("path", { d: "M34.2686 3.85047C33.8661 2.33489 32.6801 1.14116 31.1742 0.735993C28.4448 2.9025e-07 17.5 0 17.5 0C17.5 0 6.55522 2.9025e-07 3.82567 0.736069C2.31983 1.14123 1.13385 2.33489 0.731351 3.85055C-7.25625e-08 6.59768 0 12.3296 0 12.3296C0 12.3296 -7.25625e-08 18.0615 0.731351 20.8086C1.13385 22.3242 2.31983 23.4682 3.82567 23.8735C6.55522 24.6094 17.5 24.6094 17.5 24.6094C17.5 24.6094 28.4448 24.6094 31.1742 23.8734C32.6801 23.4681 33.8661 22.3242 34.2686 20.8085C35 18.0614 35 12.3295 35 12.3295C35 12.3295 35 6.59768 34.2686 3.85047Z", fill: "#FF0000" }), _jsx("path", { d: "M13.9204 17.5337V7.12544L23.0681 12.3297L13.9204 17.5337Z", fill: "white" })] }))); };
export { YouTube };
