var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from "styled-components";
export var Container = styled('div')(function (_a) {
    var theme = _a.theme;
    return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: flex;\n    margin-top: 25px;\n    gap: 40px;\n    @media only screen and (max-width: ", ") {\n      flex-direction: column;\n    }\n  "], ["\n    display: flex;\n    margin-top: 25px;\n    gap: 40px;\n    @media only screen and (max-width: ", ") {\n      flex-direction: column;\n    }\n  "])), theme.breakpoints.tablet);
});
var templateObject_1;
