var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from "styled-components";
export var TimelineContainer = styled('div')(function (_a) {
    var theme = _a.theme;
    return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    width: 60%;\n    @media only screen and (max-width: ", ") {\n      width: 100%;\n    }\n  "], ["\n    width: 60%;\n    @media only screen and (max-width: ", ") {\n      width: 100%;\n    }\n  "])), theme.breakpoints.laptop);
});
export var BlockTitle = styled('h3')(function (_a) {
    var theme = _a.theme;
    return css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    color: ", ";\n    margin-bottom: 20px;\n    width: 100%;\n  "], ["\n    color: ", ";\n    margin-bottom: 20px;\n    width: 100%;\n  "])), theme.colors.lightText);
});
export var Block = styled('div')(function (_a) {
    var theme = _a.theme;
    return css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    display: flex;\n    margin-top: 40px;\n    gap: 50px;\n    @media only screen and (max-width: ", ") {\n      flex-direction: column;\n      margin-top: 20px;\n      gap: 30px;\n    }\n  "], ["\n    display: flex;\n    margin-top: 40px;\n    gap: 50px;\n    @media only screen and (max-width: ", ") {\n      flex-direction: column;\n      margin-top: 20px;\n      gap: 30px;\n    }\n  "])), theme.breakpoints.laptop);
});
export var Knowledge = styled('p')(function (_a) {
    var theme = _a.theme;
    return css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    font-size: 20px;\n    color: ", ";\n    display: flex;\n    text-align: center;\n    background: ", ";\n    padding: 5px 8px;\n    border-radius: 8px;\n    margin: 5px;\n    line-height: 34px;\n    align-items: center;\n    @media only screen and (max-width: ", "){\n      font-size: 16px;\n      padding: 0 5px;\n      margin: 3px;\n    }\n  "], ["\n    font-size: 20px;\n    color: ", ";\n    display: flex;\n    text-align: center;\n    background: ", ";\n    padding: 5px 8px;\n    border-radius: 8px;\n    margin: 5px;\n    line-height: 34px;\n    align-items: center;\n    @media only screen and (max-width: ", "){\n      font-size: 16px;\n      padding: 0 5px;\n      margin: 3px;\n    }\n  "])), theme.colors.skills, theme.colors.blue, theme.breakpoints.laptop);
});
export var Skill = styled('p')(function (_a) {
    var theme = _a.theme;
    return css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    font-size: 22px;\n    color: ", ";\n    line-height: 34px;\n    @media only screen and (max-width: ", "){\n      font-size: 16px;\n      color: ", ";\n      display: flex;\n      text-align: center;\n      background: ", ";\n      padding: 0 5px;\n      border-radius: 8px;\n      margin: 3px;\n    }\n  "], ["\n    font-size: 22px;\n    color: ", ";\n    line-height: 34px;\n    @media only screen and (max-width: ", "){\n      font-size: 16px;\n      color: ", ";\n      display: flex;\n      text-align: center;\n      background: ", ";\n      padding: 0 5px;\n      border-radius: 8px;\n      margin: 3px;\n    }\n  "])), theme.colors.skills, theme.breakpoints.laptop, theme.colors.skills, theme.colors.blue);
});
export var SkillsBlock = styled('div')(function (_a) {
    var theme = _a.theme;
    return css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    width: 40%;\n    @media only screen and (max-width: ", ") {\n      width: 100%;\n      display: flex;\n      flex-wrap: wrap;\n    }\n  "], ["\n    width: 40%;\n    @media only screen and (max-width: ", ") {\n      width: 100%;\n      display: flex;\n      flex-wrap: wrap;\n    }\n  "])), theme.breakpoints.laptop);
});
export var KnowledgeBlock = styled('div')(function (_a) {
    var theme = _a.theme;
    return css(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    width: 40%;\n    height: 40%;\n    display: flex;\n    flex-wrap: wrap;\n    @media only screen and (max-width: ", ") {\n      width: 100%;\n    }\n  "], ["\n    width: 40%;\n    height: 40%;\n    display: flex;\n    flex-wrap: wrap;\n    @media only screen and (max-width: ", ") {\n      width: 100%;\n    }\n  "])), theme.breakpoints.laptop);
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
