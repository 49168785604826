var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
var Close = function (props) { return (_jsx("svg", __assign({ width: "100%", height: "100%", viewBox: "0 0 35 35", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props, { children: _jsx("path", { d: "M33.6913 28.1058L23.4238 17.5003L33.6913 6.89406C35.4361 5.31639 35.4361 2.75941 33.6913 1.18241C31.9451 -0.394586 29.1161 -0.393913 27.3721 1.18308L17.5 11.3814L7.62792 1.18308C5.88316 -0.39324 3.05491 -0.393913 1.30866 1.18241C-0.436097 2.76008 -0.436097 5.31706 1.30866 6.89406L11.5762 17.5003L1.30866 28.1058C-0.436097 29.6828 -0.436097 32.2398 1.30792 33.8167C3.05342 35.3944 5.88242 35.3944 7.62792 33.8167L17.5 23.6184L27.3721 33.8167C29.1183 35.3944 31.9473 35.3944 33.6921 33.8167C35.4361 32.2398 35.4361 29.6828 33.6913 28.1058Z", fill: "#04B4E0" }) }))); };
export { Close };
