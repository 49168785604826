var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from "styled-components";
import { Description } from "../../theme/styled-elements.styled";
export var FirstBlock = styled('div')(function (_a) {
    var theme = _a.theme;
    return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: flex;\n    gap: 40px;\n    margin-top: 40px;\n    @media only screen and (max-width: ", ") {\n      flex-direction: column;\n    }\n  "], ["\n    display: flex;\n    gap: 40px;\n    margin-top: 40px;\n    @media only screen and (max-width: ", ") {\n      flex-direction: column;\n    }\n  "])), theme.breakpoints.laptop);
});
export var About = styled(Description)(function (_a) {
    var theme = _a.theme;
    return css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    width: 60%;\n    font-size: 20px;\n    @media only screen and (max-width: ", ") {\n      width: 100%;\n      font-size: 16px;\n    }\n  "], ["\n    width: 60%;\n    font-size: 20px;\n    @media only screen and (max-width: ", ") {\n      width: 100%;\n      font-size: 16px;\n    }\n  "])), theme.breakpoints.laptop);
});
export var InfoBlock = styled('div')(function (_a) {
    var theme = _a.theme;
    return css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    display: flex;\n    flex-direction: column;\n    width: 40%;\n    @media only screen and (max-width: ", ") {\n      width: 100%;\n    }\n  "], ["\n    display: flex;\n    flex-direction: column;\n    width: 40%;\n    @media only screen and (max-width: ", ") {\n      width: 100%;\n    }\n  "])), theme.breakpoints.laptop);
});
export var InfoKey = styled('span')(function (_a) {
    var theme = _a.theme;
    return css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    color: ", ";\n  "], ["\n    color: ", ";\n  "])), theme.colors.blue);
});
export var InfoValue = styled('span')(function (_a) {
    var theme = _a.theme;
    return css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    color: ", ";\n    text-decoration: none;\n  "], ["\n    color: ", ";\n    text-decoration: none;\n  "])), theme.colors.text);
});
export var Title = styled('h2')(function (_a) {
    var theme = _a.theme;
    return css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    color: ", ";\n    letter-spacing: 2px;\n    margin: 40px 0 10px;\n    @media only screen and (max-width: ", ") {\n      font-size: 26px;\n    }\n  "], ["\n    color: ", ";\n    letter-spacing: 2px;\n    margin: 40px 0 10px;\n    @media only screen and (max-width: ", ") {\n      font-size: 26px;\n    }\n  "])), theme.colors.lightText, theme.breakpoints.laptop);
});
export var SecondBlock = styled('div')(function (_a) {
    var theme = _a.theme;
    return css(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    display: flex;\n    gap: 40px;\n    flex-wrap: wrap;\n    @media only screen and (max-width: ", ") {\n      flex-direction: column;\n    }\n  "], ["\n    display: flex;\n    gap: 40px;\n    flex-wrap: wrap;\n    @media only screen and (max-width: ", ") {\n      flex-direction: column;\n    }\n  "])), theme.breakpoints.laptop);
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
