var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ThemeProvider } from "./theme/ThemeProvider";
import { Route, Routes } from "react-router-dom";
import { Layout } from "./common/Components/Layout/layout";
import { Home } from "./pages/Home/home";
import { AboutMe } from "./pages/AboutMe/about-me";
import { Resume } from "./pages/Resume/resume";
import { Portfolio } from "./pages/Portfolio/portfolio";
import { Contact } from "./pages/Contact/contact";
export var App = function () {
    return (_jsx(ThemeProvider, { children: _jsx(Routes, { children: _jsxs(Route, __assign({ path: "/", element: _jsx(Layout, {}) }, { children: [_jsx(Route, { path: "/", element: _jsx(Home, {}) }), _jsx(Route, { path: "/about-me", element: _jsx(AboutMe, {}) }), _jsx(Route, { path: "/resume", element: _jsx(Resume, {}) }), _jsx(Route, { path: "/portfolio", element: _jsx(Portfolio, {}) }), _jsx(Route, { path: "/contact", element: _jsx(Contact, {}) }), _jsx(Route, { path: "*", element: _jsx(Home, {}) })] })) }) }));
};
