var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { PageLayout } from "../../common/Components/Layout/page-layout";
import { Container } from "./contact.styled";
import { InputGroup } from "./Inputs/input-container";
import { CardContainer } from "./Cards/card-container";
import { Toaster } from "react-hot-toast";
export var Contact = function () {
    var t = useTranslation().t;
    return (_jsxs(PageLayout, __assign({ title: t('contact') }, { children: [_jsxs(Container, { children: [_jsx(CardContainer, {}), _jsx(InputGroup, {})] }), _jsx(Toaster, { position: "bottom-right", toastOptions: {
                    className: '',
                    duration: 5000,
                    style: {
                        background: '#363636',
                        color: '#F5F5F5',
                    },
                } })] })));
};
