var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import armFlag from '../../assets/svg/Armenia.svg';
import ruFlag from '../../assets/svg/Russia.svg';
import ukFlag from '../../assets/svg/UnitedKingdom.svg';
import { HorizontalSelectWrapper, Option } from "./language-switcher.styled";
var lang = [
    {
        icon: armFlag,
        code: 'hy'
    },
    {
        icon: ruFlag,
        code: 'ru'
    },
    {
        icon: ukFlag,
        code: 'en'
    }
];
export var LanguageSwitcher = function () {
    var _a = useState(false), isOpen = _a[0], setIsOpen = _a[1];
    var i18n = useTranslation().i18n;
    var locale = i18n.language;
    var handleSubmit = function (lang) {
        if (lang === locale) {
            setIsOpen(!isOpen);
        }
        else {
            i18n.changeLanguage(lang);
            setIsOpen(false);
        }
    };
    var currentLang = lang.filter(function (el) { return el.code === locale; });
    var restLang = lang.filter(function (el) { return el.code !== locale; });
    var languages = isOpen || window.innerWidth < 768 ? __spreadArray(__spreadArray([], restLang, true), currentLang, true) : currentLang;
    return (_jsx(HorizontalSelectWrapper, { children: languages.map(function (ln) {
            return (_jsx(Option, __assign({ onClick: function (e) { return handleSubmit(ln.code); } }, { children: _jsx("img", { src: ln.icon, alt: "", style: { height: '32px', width: '32px' } }) }), ln.code));
        }) }));
};
