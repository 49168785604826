import im1 from './1.jpg';
import im2 from './2.jpg';
import im3 from './3.jpg';
import im4 from './4.jpg';
import im5 from './5.jpg';
import im6 from './6.jpg';
import im7 from './7.jpg';
import im8 from './8.jpg';
import im9 from './9.jpg';
import im10 from './10.jpg';
import im11 from './11.jpg';
import im12 from './12.jpg';
import im13 from './13.jpg';
import im14 from './14.jpg';
import im15 from './15.jpg';
import im16 from './16.jpg';
import im17 from './17.jpg';
import im18 from './18.jpg';
import im19 from './19.jpg';
import im20 from './20.jpg';
import im21 from './21.jpg';
export var photoGallery = [
    {
        src: im1,
        id: 1,
        name: 'Name',
        location: ''
    },
    {
        src: im2,
        id: 2,
        name: 'Name',
        location: ''
    },
    {
        src: im3,
        id: 3,
        name: 'Name',
        location: ''
    },
    {
        src: im4,
        id: 4,
        name: 'Name',
        location: ''
    },
    {
        src: im5,
        id: 5,
        name: 'Name',
        location: ''
    },
    {
        src: im6,
        id: 6,
        name: 'Name',
        location: ''
    },
    {
        src: im7,
        id: 7,
        name: 'Name',
        location: ''
    },
    {
        src: im8,
        id: 8,
        name: 'Name',
        location: ''
    },
    {
        src: im9,
        id: 9,
        name: 'Name',
        location: ''
    },
    {
        src: im10,
        id: 10,
        name: 'Name',
        location: ''
    },
    {
        src: im11,
        id: 11,
        name: 'Name',
        location: ''
    },
    {
        src: im12,
        id: 12,
        name: 'Name',
        location: ''
    },
    {
        src: im13,
        id: 13,
        name: 'Name',
        location: ''
    },
    {
        src: im14,
        id: 14,
        name: 'Name',
        location: ''
    },
    {
        src: im15,
        id: 15,
        name: 'Name',
        location: ''
    },
    {
        src: im16,
        id: 16,
        name: 'Name',
        location: ''
    },
    {
        src: im17,
        id: 17,
        name: 'Name',
        location: ''
    },
    {
        src: im18,
        id: 18,
        name: 'Name',
        location: ''
    },
    {
        src: im19,
        id: 19,
        name: 'Name',
        location: ''
    },
    {
        src: im20,
        id: 20,
        name: 'Name',
        location: ''
    },
    {
        src: im21,
        id: 21,
        name: 'Name',
        location: ''
    },
];
