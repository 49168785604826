var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from "styled-components";
export var StyledButton = styled('button')(function () { return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    width: 190px;\n    height: 40px;\n    border-radius: 50px;\n    background: transparent;\n    cursor: pointer;\n    transition: 0.3s;\n    font-size: 16px;\n  "], ["\n    width: 190px;\n    height: 40px;\n    border-radius: 50px;\n    background: transparent;\n    cursor: pointer;\n    transition: 0.3s;\n    font-size: 16px;\n  "]))); });
export var Description = styled('p')(function (_a) {
    var theme = _a.theme;
    return css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    font-size: 18px;\n    color: ", ";\n    @media only screen and (max-width: ", ") {\n      font-size: 14px;\n    }\n  "], ["\n    font-size: 18px;\n    color: ", ";\n    @media only screen and (max-width: ", ") {\n      font-size: 14px;\n    }\n  "])), theme.colors.text, theme.breakpoints.laptop);
});
export var SocialNetworks = styled('div')(function () { return css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    display: flex;\n    gap: 15px;\n    justify-content: end;\n    align-items: center;\n    margin: 10px;\n  "], ["\n    display: flex;\n    gap: 15px;\n    justify-content: end;\n    align-items: center;\n    margin: 10px;\n  "]))); });
export var IconButton = styled('a')(function () { return css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    cursor: pointer;\n  "], ["\n    cursor: pointer;\n  "]))); });
export var AlbumPhoto = styled('div')(function () { return css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    &:hover {\n      transform: scale(1.1);\n      transition: 0.8s ease-in-out;\n    }\n  "], ["\n    &:hover {\n      transform: scale(1.1);\n      transition: 0.8s ease-in-out;\n    }\n  "]))); });
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
