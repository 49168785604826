var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from "styled-components";
export var PageLayoutContainer = styled('div')(function (_a) {
    var theme = _a.theme;
    return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    background: ", ";\n    padding: 60px;\n    width: 100%;\n    min-height: 100vh;\n    @media only screen and (max-width: ", ") {\n      padding: 20px;\n    }\n  "], ["\n    background: ", ";\n    padding: 60px;\n    width: 100%;\n    min-height: 100vh;\n    @media only screen and (max-width: ", ") {\n      padding: 20px;\n    }\n  "])), theme.colors.background, theme.breakpoints.laptop);
});
export var Title = styled('h2')(function (_a) {
    var theme = _a.theme, isOpen = _a.isOpen;
    return css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    color: ", ";\n    letter-spacing: 2px;\n    filter: ", ";\n  "], ["\n    color: ", ";\n    letter-spacing: 2px;\n    filter: ", ";\n  "])), theme.colors.lightText, isOpen ? 'blur(2px)' : 'none');
});
export var Children = styled('div')(function (_a) {
    var isOpen = _a.isOpen;
    return css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    filter: ", ";\n    height: 90%;\n  "], ["\n    filter: ", ";\n    height: 90%;\n  "])), isOpen ? 'blur(2px)' : 'none');
});
export var Content = styled('div')(function () { return css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    display: flex;\n    justify-content: space-between;\n    align-items: center\n  "], ["\n    display: flex;\n    justify-content: space-between;\n    align-items: center\n  "]))); });
export var SwitcherContainer = styled('div')(function (_a) {
    var theme = _a.theme;
    return css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    @media only screen and (max-width: ", ") {\n      display: none;\n    }\n  "], ["\n    @media only screen and (max-width: ", ") {\n      display: none;\n    }\n  "])), theme.breakpoints.tablet);
});
export var IconsContainer = styled('button')(function (_a) {
    var theme = _a.theme;
    return css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    display: none;\n    background: transparent;\n    border: none;\n    position: fixed;\n    top: 30px;\n    right: 20px;\n    z-index: 10;\n    @media only screen and (max-width: ", ") {\n      display: block;\n    }\n  "], ["\n    display: none;\n    background: transparent;\n    border: none;\n    position: fixed;\n    top: 30px;\n    right: 20px;\n    z-index: 10;\n    @media only screen and (max-width: ", ") {\n      display: block;\n    }\n  "])), theme.breakpoints.tablet);
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
