var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Cards } from "./cards";
import * as Icons from "../../../common/Components/Icons";
import { CardsContainer } from "./cards.styled";
export var CardContainer = function () {
    return (_jsxs(CardsContainer, { children: [_jsx(Cards, __assign({ contact: 'Yerevan, Armenia' }, { children: _jsx(Icons.Location, { height: 35 }) })), _jsx("a", __assign({ href: "tel:+37493793469" }, { children: _jsx(Cards, __assign({ contact: '+37493793469' }, { children: _jsx(Icons.Phone, { height: 35 }) })) })), _jsx("a", __assign({ href: "mailto:arenmatinyan95@gmail.com" }, { children: _jsx(Cards, __assign({ contact: 'arenmatinyan95@gmail.com' }, { children: _jsx(Icons.MailCard, { height: 35 }) })) })), _jsx("a", __assign({ href: "https://t.me/arenmatinyan" }, { children: _jsx(Cards, __assign({ contact: 'arenmatinyan' }, { children: _jsx(Icons.Telegram, { height: 35 }) })) }))] }));
};
