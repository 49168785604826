var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Children, Content, IconsContainer, PageLayoutContainer, SwitcherContainer, Title } from "./page-layout.styled";
import { useEffect, useState } from "react";
import { LanguageSwitcher } from "../LanguageSwitcher/language-switcher";
import * as Icons from '../Icons';
import { HamburgerMenu } from "../HamburgerMenu/hamburger-menu";
export var PageLayout = function (_a) {
    var children = _a.children, title = _a.title;
    var _b = useState(false), isOpen = _b[0], setIsOpen = _b[1];
    useEffect(function () {
        window.scrollTo({ top: 0 });
    }, []);
    var closeMenu = function () {
        if (isOpen)
            setIsOpen(false);
    };
    return (_jsxs(PageLayoutContainer, { children: [_jsxs(Content, { children: [_jsx(Title, __assign({ isOpen: isOpen }, { children: title })), _jsx(SwitcherContainer, { children: _jsx(LanguageSwitcher, {}) }), _jsx(IconsContainer, __assign({ onClick: function () { return setIsOpen(!isOpen); } }, { children: isOpen ? _jsx(Icons.Close, { width: 25, height: 25 }) : _jsx(Icons.Hmburger, { width: 25, height: 20 }) }))] }), isOpen && _jsx(HamburgerMenu, {}), _jsx(Children, __assign({ isOpen: isOpen, onClick: closeMenu }, { children: children }))] }));
};
